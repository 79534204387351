/**
 * Created by andy on 9/7/16.
 */
export default [
  {
    title: 'django-asyncmailer',
    notes: `
- An async email sending solution with load balancing and routing among multiple SMTP credentials
- Now sending 100k+ emails per month with a delivery rate of more than 98%
`,
    date: 'Aug. 2016',
    featured: true,
  },
  {
    title: 'ResumeWorks',
    notes: `
- 2nd Best Hack of Education in HackDuke
- A tool for creating different versions of resumes to fit each job application
- User can choose a subset of their experiences to match with the company's interest
`,
    date: 'Nov. 2015',
    featured: false,
  },
  {
    title: 'Maps OnPoint',
    notes: `
- First Place in Hackathon @ Georgia Tech College of Computing
- Web app for planning trip navigation with a quick stop for food at any point along the route
`,
    date: 'Sep. 2015',
    featured: false,
  },
];
